'use strict';

angular.module('enervexSalesappApp').controller('AccountInviteCtrl', function($scope, $stateParams, _, Job, Account, Invitation, $q) {
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	$scope.message = {};
	$scope.newInvites = [
		{email: ''},
		{email: ''},
		{email: ''}
	];
	$scope.sendInvites = function(newInvites, message){
		var _newInvites = []
		_.each(invites, function(invite){
			if (invite.email != ''){
				_newInvites.push(invite);
			}
		})
		var promises = _.map(_newInvites, function(newInvite){
			return Invitation.save({
				accountId: $stateParams.accountId,
			}, {
				email: newInvite.email,
				message: message.newMessage,
				role: 'member'
			}).$promise
		})
		$q.all(promises).then(function(){
			$scope.invites = [
				{email: ''},
				{email: ''},
				{email: ''}
			];
			$scope.message = {};
			setTimeout(function() {
				getInvites();
			},2000);
		})
	}
	$scope.removeFromTeam = function(member){
		$scope.account.members = _.without($scope.account.members, member);
		Account.update({
			id: $stateParams.accountId,
		},{
			members: $scope.account.members
		}).$promise.then(function(res){
			$scope.account = res;
		});
		$scope.removeConfirm = false;
	}
	$scope.checkEmails = function(){
		if($scope.invites[$scope.invites.length - 1].email != '') {
			$scope.invites.push({email: ''})
		}
	}

	getInvites();
	function getInvites(){
		Invitation.query({
			accountId: $stateParams.accountId,
			status:'pending'
		}).$promise.then(function(invites){
			$scope.invites = invites;
		})
		Account.get({
			id: $stateParams.accountId
		}).$promise.then(function(res){
			$scope.account = res;
		});
	}

});
